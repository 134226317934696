import React from "react"
import "../scss/bootstrap-custom.scss"
import "../lib/animate-3.7.2.css"

import HeaderEn from "./header.en"
import Footer from "./footer"
import LinkBandEn from "./linkband.en"
import SEO from "./seo"

const LayoutEn = ({ showEu2020, location, description, lang, meta, title, children }) => {
  return (
    <>
      <SEO location={location} description={description} lang={lang} meta={meta} title={title}/>
      <HeaderEn lang={lang} showEu2020={showEu2020}/>
      <main>{children}</main>
      <LinkBandEn lang={lang}/>
      <Footer lang={lang}/>
    </>
  )
}

export default LayoutEn
