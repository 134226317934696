import React from "react"

import { graphql } from "gatsby"
import { FaEnvelope, FaMapMarker, FaPhone } from "react-icons/fa"
import LayoutEn from "../../components/layout.en"
import Slider from "../../components/slider"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"

const IndexPage = ({ location, data }) => {
  const contact = data.allContactYaml.edges[0].node.contact

  const title = "Contact Us"
  const subtitle = "Reach out to us!"

  return (
    <LayoutEn location={location} title={title} description={subtitle}>

      <Slider title={title} subtitle={subtitle}/>

      <Container style={{ paddingBottom: "2em" }}>
        <h4>Message Us!</h4>
        <Form
          method="post"
          action="https://getform.io/f/bf89946a-672a-4205-bda9-00f5fdd65c1c"
        >
          <Row style={{ paddingBottom: "1em" }}>
            <Col>
              <Form.Control placeholder="Name" name="name"/>
            </Col>
            <Col>
              <Form.Control placeholder="Email" name="email"/>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "1em" }}>
            <Col>
              <Form.Control placeholder="Subject" name="subject"/>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "1em" }}>
            <Col>
              <Form.Control as="textarea" placeholder="Message" name="message"/>
              <Form.Text className="text-muted">
                Names and emails are never handed over to third parties.
              </Form.Text>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "1em" }}>
            <Col>
              <Button variant="primary" type="submit">
                Send message
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <Container>
        <Row>
          <Col>
            <h4>Visit Us!</h4>
            <p style={{ lineHeight: "2.5em" }}>
              <FaEnvelope size="1.5em"/> <a target="_blank" rel="noreferrer"
                                            href={"mailto:" + contact.email}>{contact.email}</a> <br/>
              <FaPhone size="1.5em"/> {contact.phone} ({contact.phonePerson}) <br/>
              <FaMapMarker size="1.5em"/> <a href={contact.googleBusiness}> {contact.address} </a> <br/>
            </p>
            <iframe
              title={contact.address}
              src={contact.mapSrc}
              width="100%"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
            ></iframe>
          </Col>
        </Row>
      </Container>
    </LayoutEn>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContactYaml {
      edges {
        node {
          contact {
            address
            email
            phone
            phonePerson
            mapSrc
            googleBusiness
          }
        }
      }
    }
  }
`
